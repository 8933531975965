body {
  margin: 0;

  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ui {
  font-family: "Poppins", sans-serif !important;
}

.header,
button {
  font-family: "Poppins", sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

.hide-scroll-bar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.legal > p {
  font-size: 1em;
}

.no-border {
  border: none !important;
  border-radius: 0px !important;
}
